<template>
    <div class="movie-app__container movie-app__pt-4">
        <div class="movie-app__row">
            <div class="movie-app__col-xs-9 movie-app__col-sm-6 movie-app__pt-2">
                <input type="text" v-model="search" class="movie-app__form-control" placeholder="Film suchen">
                                    <span class="movie-app__form-control-feedback">
                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 8.31378C0 4.02111 3.48112 0.540527 7.77442 0.540527C12.0677 0.540527 15.5489 4.02111 15.5489 8.31378C15.5489 9.96444 15.0339 11.4953 14.1568 12.7546L19.9218 18.5187L17.9809 20.4593L12.215 14.6952C10.9565 15.5722 9.4254 16.087 7.77445 16.087C3.48112 16.087 0 12.6064 0 8.31378ZM1.71429 8.19906C1.71429 11.4821 4.37588 14.1433 7.65945 14.1433C10.943 14.1433 13.6046 11.4821 13.6046 8.19906C13.6046 4.916 10.943 2.25481 7.65945 2.25481C4.37591 2.25481 1.71429 4.916 1.71429 8.19906Z" fill="#C0C1C6"/>
                    </svg>
                    </span>
            </div>
            <div class="movie-app__col-xs-3 movie-app__col-sm-6 movie-app__pt-2" style="padding-left:0;">
                <p v-show="search" v-on:click="search = ''" class="movie-app__link" style="margin-top:5px;">Abbrechen</p>
            </div>
        </div>
        <div class="movie-app__row">
            <div class="movie-app__col-xs-9 movie-app__col-sm-6 movie-app__pt-2">
                <select v-model="selectedDay" @change="selectDay()" class="movie-app__form-control movie-app__mb-2">
                    <option value="" selected>Datum wählen</option>
                    <option v-for="product in products" :value="product.id" :key="product.id">{{ product.name }}</option>
                </select>
            </div>
            <div class="movie-app__col-xs-12 movie-app__col-sm-12 movie-app__col-md-4 movie-app__pt-2">
                <button @click="actDay()" class="movie-app__btn movie-app__mr-2" :class="{ active: aktDayButton}">Heute</button> 
                <button @click="allData()" class="movie-app__btn" :class="{ active: !selectedDay && !aktDayButton}">Aktuelle Woche</button>
            </div>
        </div>
        <div class="movie-app__row">
            <div v-if="movieFound" class="movie-app__col-xs-12 movie-app__pt-2">
                <p><b>Vorführungen {{ dayName }} </b></p>
            </div>
        </div>
    </div>
    <div class="movie-app__container movie-app__pt-4">
        <div class="movie-app__row movie-app__row-flex">
            <div v-for="item in filteredList" :key="item.movie_id" class="movie-app__col-xs-6 movie-app__col-sm-4 movie-app__col-md-3 movie-app__pb-2">
                <router-link :to="{ name: 'movie', params: { id: item.movie_id, pageTitle: item.title }}" class="movie-app__link-movie-list">
                    <div class="movie-app__col-xs-12 movie-app__p-0">
                        <div class="movie-app__list-movie-image" :style="{ backgroundImage: 'url(' + item.moviePoster + ')' }"
></div>
                    </div>
                    <div class="movie-app__col-xs-12 movie-app__p-sm-0">
                        <h4 class="movie-app__list-movie-name">{{ item.title }} {{ item.ov }}</h4>
                        <p class="movie-app__list-movie-data">
                            {{ item.genre }}  | ab {{ item.fsk }}<br>
                            Kinostart: {{ item.start }}
                        </p>
                    </div>
                </router-link>
            </div>
        </div>
        <div class="movie-app__row"  v-if="!movieFound">
            <div class="movie-app__col-xs-12 movie-app__pt-4">
                <h2>Es wurden keine Vorführungen {{ dayName }} gefunden.</h2>
            </div>
        </div>
    </div>
    
</template>

<script>
export default ({
    data() {
        return {
            search: '',
            selectedDay: new Date().getDay(),
            aktDayButton: new Date().getDay(),
            aktDay: new Date().getDay(),
            listItems: [],
            dayName: '',
            movieFound: true,
            products: [
        {id: '4', name: 'Do. 11.01.', date: '11.01.'},
        {id: '5', name: 'Fr. 12.01.', date: '12.01.'},
        {id: '6', name: 'Sa. 13.01.', date: '13.01.'},
        {id: '0', name: 'So. 14.01.', date: '14.01.'},
        {id: '1', name: 'Mo. 15.01.', date: '15.01.'},
        {id: '2', name: 'Di. 16.01.', date: '16.01.'},
        {id: '3', name: 'Mi. 17.01.', date: '17.01.'},
            ],
        }
    },
    methods: {
        async getData() {
            console.log("https://interface.datenlieferung-streamingdienste.de/api/entry/movieList/1000")
            await fetch("https://interface.datenlieferung-streamingdienste.de/api/entry/movieList/1000")
                .then(res => res.json())
                .then(data => this.listItems = data)
                .catch(err => console.log(err.message));
        },
        async changeDay() {
            await fetch("https://interface.datenlieferung-streamingdienste.de/api/entry/movieList/" + this.selectedDay)
                .then(res => res.json())
                .then(data => this.listItems = data)
                .catch(err => console.log(err.message));
            if ( this.selectedDay != '' ) {
                this.dayName = 'am ' + this.products.find(x => x.id == this.selectedDay).date
            } else {
                this.dayName = 'vom ' + this.products[0].date + ' - ' + this.products[this.products.length - 1].date
            }  
        },
        allData() {
            this.aktDayButton = ''
            this.selectedDay = ''
            this.dayName = 'vom ' + this.products[0].date + ' - ' + this.products[this.products.length - 1].date
            this.getData()
        },
        actDay() {
            this.aktDayButton = this.aktDay
            this.selectedDay = this.aktDay
            this.changeDay()
        },
        selectDay() {
            if ( this.selectedDay == this.aktDay ) {
                this.aktDayButton = this.aktDay
            } else {
                this.aktDayButton = ''
            }
            this.changeDay()
        }
    },
    mounted() {
        this.changeDay()        
    },
    computed: {
        filteredList() {
            const result = this.listItems.filter(post => {return post.title.toLowerCase().includes(this.search.toLowerCase())})
            if (result.length) {
                this.movieFound = true
            } else {
                this.movieFound = false
            }
            return result
        }
    }
})
</script>

<style>

</style>