<template>
  <div class="movie-app__container movie-app__pt-4 movie-app__pb-4">
    <div class="movie-app__row">
      <div class="movie-app__col-xs-12">
        <router-link :to="{ name: 'home'}">
          <div class="movie-app__link">
            <span class="movie-app__svg_back">
              <svg width="4" height="8" viewBox="0 0 4 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 7.28951L1.19884 3.99941L4 0.710494L3.39203 0L0 4.00059L3.39203 8L4 7.28951Z" fill="#29293A"/>
              </svg>
              Zurück
            </span>
          </div>
        </router-link>
      </div>
    </div>
  </div>
  <div v-if="movie.movie_image" class="movie-app__header">
    <img :src="movie.movie_image" class="movie-app__header-img"/>
  </div>
  <div class="movie-app__container movie-app__header-container">
    <div class="movie-app__row">
      <div class="movie-app__col-xs-12 movie-app__text-center">
        <h1 class="movie-app__page-header-article">                 
          <span class="movie-app__page-header-article__title">{{ movie.title }}</span>
        </h1>
        <p class="movie-app__page-header-article__overline">
          <span v-if="movie.genre">{{ movie.genre }} </span>
          <span v-if="movie.country"> | {{ movie.country }} ({{ movie.year }}) </span>
          <span v-if="movie.fsk"> | ab {{ movie.fsk }} </span>
          <span v-if="movie.length"> | Laufzeit: {{ movie.length }} </span>
          <span v-if="movie.start"> | Neustart: {{ movie.start }} </span>
        </p>
      </div>
    </div>
  </div>
  <div class="movie-app__movie-container">
  <TabsWrapper>
    <Tab title="Filminfo">
      <div v-if="movie.teaser" class="movie-app__container">
        <div class="movie-app__row">
          <div class="movie-app__col-xs-12">
            <p class="movie-app__font_3">{{ movie.teaser }}</p>
            <p class="movie-app__font_8">Dieser Text wurde von den Kinospezialisten unserer Partneragentur verfasst.</p>
          </div>
        </div>
      </div>
      <div v-if="movie.trailer" class="movie-app__container movie-app__pt-4 movie-app__pb-4">
        <div class="movie-app__row">
          <div class="movie-app__col-xs-12">
            <video :poster="videoPoster" controls>
              <source :src="movie.trailer" type=video/mp4>
            </video>
          </div>
        </div>
      </div>
      <div class="movie-app__container movie-app__movie-data">
        <div v-if="movie.director" class="movie-app__row">
          <div class="movie-app__col-xs-4">
            <p><b>Regie</b></p>
          </div>
          <div class="movie-app__col-xs-8">
            <p>{{ movie.director }}</p>
          </div>
        </div>
        <div v-if="movie.actor" class="movie-app__row">
          <div class="movie-app__col-xs-4">
            <p class="movie-app__mt-2"><b>Darsteller</b></p>
          </div>
          <div class="movie-app__col-xs-8">
            <p class="movie-app__mt-2">{{ movie.actor }}</p>
          </div>
        </div>
        <div v-if="movie.lender" class="movie-app__row">
          <div class="movie-app__col-xs-4">
            <p class="movie-app__mt-2"><b>Copyright</b></p>
          </div>
          <div class="movie-app__col-xs-8">
            <p class="movie-app__mt-2">{{ movie.lender }}</p>
          </div>
        </div>
      </div>
      <div v-if="movie.movie_images" class="movie-app__container movie-app__pb-4">
        <div class="movie-app__row">
          <div class="movie-app__col-xs-12">
            <Carousel v-if="movie.movie_images" v-bind="settings" :breakpoints="breakpoints" :wrap-around="true">
              <Slide v-for="slide in movie.movie_images" :key="slide">
                  <div class="movie-app__carousel__item">
                    <img :src="slide" />
                  </div>
              </Slide>

              <template #addons>
                <Navigation />
              </template>
            </Carousel>
          </div>
        </div>
      </div>
    </Tab>
    <Tab title="Vorführungen">
    <div class="movie-app__container movie-app__pt-4">
        <div class="movie-app__row">
            <div class="movie-app__col-xs-9 movie-app__col-sm-6 movie-app__pt-2">
                <input type="text" v-model="search" class="movie-app__form-control" placeholder="Kino suchen">
                                    <span class="movie-app__form-control-feedback">
                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 8.31378C0 4.02111 3.48112 0.540527 7.77442 0.540527C12.0677 0.540527 15.5489 4.02111 15.5489 8.31378C15.5489 9.96444 15.0339 11.4953 14.1568 12.7546L19.9218 18.5187L17.9809 20.4593L12.215 14.6952C10.9565 15.5722 9.4254 16.087 7.77445 16.087C3.48112 16.087 0 12.6064 0 8.31378ZM1.71429 8.19906C1.71429 11.4821 4.37588 14.1433 7.65945 14.1433C10.943 14.1433 13.6046 11.4821 13.6046 8.19906C13.6046 4.916 10.943 2.25481 7.65945 2.25481C4.37591 2.25481 1.71429 4.916 1.71429 8.19906Z" fill="#C0C1C6"/>
                    </svg>
                    </span>
            </div>
            <div class="movie-app__col-xs-3 movie-app__col-sm-6 movie-app__pt-2" style="padding-left:0;">
                <p v-show="search" v-on:click="search = ''" class="movie-app__link" style="margin-top:5px;">Abbrechen</p>
            </div>
        </div>
        <div class="movie-app__row">
            <div class="movie-app__col-xs-9 movie-app__col-sm-6 movie-app__pt-2">
                <select v-model="selectedDay" @change="selectDay()" class="movie-app__form-control movie-app__mb-2">
                    <option value="" selected>Datum wählen</option>
                    <option v-for="product in products" :value="product.id" :key="product.id">{{ product.name }}</option>
                </select>
            </div>
            <div class="movie-app__col-xs-12 movie-app__col-sm-12 movie-app__col-md-4 movie-app__pt-2">
                <button @click="actDay()" class="movie-app__btn movie-app__mr-2" :class="{ active: aktDayButton}">Heute</button> 
                <button @click="allData()" class="movie-app__btn" :class="{ active: !selectedDay && !aktDayButton}">Aktuelle Woche</button>
            </div>
        </div>
        <div class="movie-app__row">
            <div v-if="movieFound" class="movie-app__col-xs-12 movie-app__pt-2">
                <p><b>Vorführungen {{ dayName }}</b></p>
            </div>
            <div v-if="!movieFound" class="movie-app__col-xs-12 movie-app__pt-5">
                <h2>Es wurden keine Vorführungen {{ dayName }} gefunden.</h2>
            </div>
        </div>
    </div>
      <div class="movie-app__container">
        <div v-for="item in filteredList" :key="item.cinema_id" class="movie-app__row movie-app__cinema-item">
          <div class="movie-app__col-xs-12">
              <h4 class="movie-app__list-name">{{ item.name }}</h4>
              <p class="movie-app__list-address movie-app__mt-2">{{ item.address }}, {{ item.zip }} {{ item.place }}</p>
              <p class="movie-app__mt-4">
                <router-link :to="{ name: 'cinema', params: { id: item.cinema_id, pageTitle: item.name }}" class="movie-app__link">
                  Zur Programmseite des Kinos
                </router-link>
              </p>
          </div>
          <div class="movie-app__col-xs-12 movie-app__pb-4">
              <Playtimes :playtimes="item.playtimes" :key="componentKey"/>
          </div>
        </div>
      </div>
    </Tab>
  </TabsWrapper>
  </div>
</template>

<script>
import Tab from '../components/Tab.vue'
import TabsWrapper from '../components/TabsWrapper.vue'
import Playtimes from '../components/Playtimes.vue'
import { Carousel, Navigation, Slide } from 'vue3-carousel'

import 'vue3-carousel/dist/carousel.css'
export default {
  name: 'WrapAround',
  components: { Tab, TabsWrapper, Playtimes, Carousel, Slide, Navigation},
  props: ['id'],
  data() {
    return {
      componentKey: 0,
      search: '',
      selectedDay: new Date().getDay(),
      aktDayButton: new Date().getDay(),
      aktDay: new Date().getDay(),
      wrapperDynamicKey: 0,
      countMovies: 0,
      movie: [],
      cinemaList: [],
      dayName: '',
      videoPoster: '',
      movieFound: true,
      products: [
        {id: '4', name: 'Do. 11.01.', date: '11.01.'},
        {id: '5', name: 'Fr. 12.01.', date: '12.01.'},
        {id: '6', name: 'Sa. 13.01.', date: '13.01.'},
        {id: '0', name: 'So. 14.01.', date: '14.01.'},
        {id: '1', name: 'Mo. 15.01.', date: '15.01.'},
        {id: '2', name: 'Di. 16.01.', date: '16.01.'},
        {id: '3', name: 'Mi. 17.01.', date: '17.01.'},
      ],
      settings: {
          itemsToShow: 1.5,
          snapAlign: 'center',
      }
    }
  },
  methods: {
    async getData() {
        await fetch("https://interface.datenlieferung-streamingdienste.de/api/entry/movieById/" + this.id)
            .then(res => res.json())
            .then(data => this.movie = data)
            .catch(err => console.log(err.message));
        if ( this.movie.movie_images.length ) {
          this.videoPoster = this.movie.movie_images[this.movie.movie_images.length - 1]
        } else {
          this.videoPoster = this.movie.image
        }
        
    },
    async getDataCinema() {
        await fetch("https://interface.datenlieferung-streamingdienste.de/api/entry/cinemaByMovie/"+ this.id + this.selectedDay)
            .then(res => res.json())
            .then(data => this.cinemaList = data)
            .catch(err => console.log(err.message));
        this.countMovies = Object.keys(this.cinemaList).length
        this.wrapperDynamicKey += 1
        if ( this.selectedDay != '' ) {
            this.dayName = 'am ' + this.products.find(x => x.id == this.selectedDay).date
        } else {
            this.dayName = 'vom ' + this.products[0].date + '-' + this.products[this.products.length - 1].date
        }
        this.forceRerender()
    },
    allData() {
        this.aktDayButton = ''
        this.selectedDay = ''
        this.getDataCinema()
    },
    actDay() {
        this.aktDayButton = this.aktDay
        this.selectedDay = this.aktDay
        this.getDataCinema()
    },
    selectDay() {
        if ( this.selectedDay == this.aktDay ) {
            this.aktDayButton = this.aktDay
        } else {
            this.aktDayButton = ''
        }
        this.getDataCinema()
    },
    forceRerender() {
      this.componentKey += 1;
    }
  },
  mounted() {
      this.getData(),
      this.getDataCinema()
  },
  computed: {
      filteredList() {
        const result = Object.values(this.cinemaList).filter(post => {
          return post.name.toLowerCase().includes(this.search.toLowerCase()) || post.address.toLowerCase().includes(this.search.toLowerCase()) || post.place.toLowerCase().includes(this.search.toLowerCase())
         })
        if (result.length) {
            this.movieFound = true
        } else {
            this.movieFound = false
        }
        return result
      }
  }
}
</script>


<style>
.carousel__item {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__item img {
    max-height: 100%;
}

.carousel__slide {
  padding: 10px;
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  background-color: #fff;
  border-radius: 50%;
}
</style>