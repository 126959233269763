<template>
    <div>
        <div class="movie-app__container movie-app__pt-4">
          <div class="movie-app__row">
              <div class="movie-app__col-xs-9 movie-app__col-sm-6 movie-app__pt-2">
                  <input type="text" v-model="search" class="movie-app__form-control" placeholder="Kino suchen">
                    <span class="movie-app__form-control-feedback">
                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 8.31378C0 4.02111 3.48112 0.540527 7.77442 0.540527C12.0677 0.540527 15.5489 4.02111 15.5489 8.31378C15.5489 9.96444 15.0339 11.4953 14.1568 12.7546L19.9218 18.5187L17.9809 20.4593L12.215 14.6952C10.9565 15.5722 9.4254 16.087 7.77445 16.087C3.48112 16.087 0 12.6064 0 8.31378ZM1.71429 8.19906C1.71429 11.4821 4.37588 14.1433 7.65945 14.1433C10.943 14.1433 13.6046 11.4821 13.6046 8.19906C13.6046 4.916 10.943 2.25481 7.65945 2.25481C4.37591 2.25481 1.71429 4.916 1.71429 8.19906Z" fill="#C0C1C6"/>
                    </svg>
                    </span>
              </div>
              <div class="movie-app__col-xs-3 movie-app__col-sm-6 movie-app__pt-2" style="padding-left:0;">
                <p v-show="search" v-on:click="search = ''" class="movie-app__link" style="margin-top:5px;">Abbrechen</p>
              </div>
          </div>
        </div>
        <div class="movie-app__container movie-app__pt-4">
            <div class="movie-app__row movie-app__cinema-item movie-app__gray-hover" v-for="item in filteredList" :key="item.cinema_id">
                <router-link :to="{ name: 'cinema', params: { id: item.cinema_id, pageTitle:  item.name}}">
                    <div class="movie-app__col-xs-12">
                        <div class="movie-app__col-xs-9">
                            <div class="movie-app__row">
                                <div class="movie-app__col-xs-9 movie-app__col-md-7 movie-app__p-0">
                                    <h4 class="movie-app__list-name">{{ item.name }}</h4>
                                </div>
                                <div class="movie-app__col-xs-9 movie-app__col-md-5 movie-app__p-0">
                                    <p class="movie-app__list-address">{{ item.address }}, {{ item.zip }} {{ item.place }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-3">
                                <p class="movie-app__text-right movie-app__link">Zum Kino</p>
                        </div>
                    </div>
                </router-link>
            </div>
            <div class="movie-app__row"  v-if="!cinemaFound">
                <div class="movie-app__col-xs-12 movie-app__pt-4">
                    <h2>Es wurde kein Kino gefunden.</h2>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default ({
    data() {
        return {
            search: '',
            searchDeleted: '',
            listItems: [],
            cinemaFound: true,
        }
    },
    methods: {
        async getData() {
            await fetch("https://interface.datenlieferung-streamingdienste.de/api/entry/cinemaList")
                .then(res => res.json())
                .then(data => this.listItems = data)
                .catch(err => console.log(err.message));
        },
        say(message) {
            alert(message)
        }
    },
    mounted() {
        this.getData()
    },
    computed: {
        filteredList() {
            const result = this.listItems.filter(post => {
                return post.name.toLowerCase().includes(this.search.toLowerCase()) || post.address.toLowerCase().includes(this.search.toLowerCase()) || post.place.toLowerCase().includes(this.search.toLowerCase())
            })
            if (result.length) {
                this.cinemaFound = true
            } else {
                this.cinemaFound = false
            }
            return result
        }
    }
})
</script>