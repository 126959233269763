<template>
  <div class="movie-app__container movie-app__pt-4 movie-app__pb-4">
    <div class="movie-app__row">
      <div class="movie-app__col-xs-12">
        <router-link :to="{ name: 'home'}">
          <div class="movie-app__link">
            <span class="movie-app__svg_back">
              <svg width="4" height="8" viewBox="0 0 4 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 7.28951L1.19884 3.99941L4 0.710494L3.39203 0L0 4.00059L3.39203 8L4 7.28951Z" fill="#29293A"/>
              </svg>
              Zurück
            </span>
          </div>
        </router-link>
      </div>
    </div>
  </div>
  <div class="movie-app__container movie-app__pb-4">
    <div class="movie-app__row">
      <div class="movie-app__col-xs-12 movie-app__cinema-name">
        <h1>{{ cinema.name }}</h1>
      </div>
      <div class="movie-app__col-xs-12 movie-app__cinema-address">
        <a class="movie-app__link" :href="'http://maps.google.com/?q=' + cinema.name + ',' + cinema.address + ',' + cinema.place + ',' +cinema.zip" target="_blank">
          <span class="movie-app__svg">
            <svg width="12" height="17" viewBox="0 0 12 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.00008 0.166504C2.77508 0.166504 0.166748 2.77484 0.166748 5.99984C0.166748 9.47484 3.85008 14.2665 5.36675 16.0915C5.70008 16.4915 6.30841 16.4915 6.64175 16.0915C8.15008 14.2665 11.8334 9.47484 11.8334 5.99984C11.8334 2.77484 9.22508 0.166504 6.00008 0.166504ZM6.00008 8.08317C4.85008 8.08317 3.91675 7.14984 3.91675 5.99984C3.91675 4.84984 4.85008 3.9165 6.00008 3.9165C7.15008 3.9165 8.08341 4.84984 8.08341 5.99984C8.08341 7.14984 7.15008 8.08317 6.00008 8.08317Z" fill="#29293A"/>
            </svg>
            <b>{{ cinema.address }}, {{ cinema.zip }} {{ cinema.place }}</b>
          </span>
        </a>
      </div>
      <div class="movie-app__col-xs-12 movie-app__pt-4 movie-app__cinema-link">
        <a class="movie-app__link" style="margin-left:18px;"  :href="cinema.website" target="_blank">
            Zur Programmseite des Kinos  
        </a>
      </div>
    </div>
  </div>
    <div class="movie-app__container movie-app__pt-4">
        <div class="movie-app__row">
            <div class="movie-app__col-xs-9 movie-app__col-sm-6 movie-app__pt-2">
                <input type="text" v-model="search" class="movie-app__form-control" placeholder="Film suchen">
                                    <span class="movie-app__form-control-feedback">
                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 8.31378C0 4.02111 3.48112 0.540527 7.77442 0.540527C12.0677 0.540527 15.5489 4.02111 15.5489 8.31378C15.5489 9.96444 15.0339 11.4953 14.1568 12.7546L19.9218 18.5187L17.9809 20.4593L12.215 14.6952C10.9565 15.5722 9.4254 16.087 7.77445 16.087C3.48112 16.087 0 12.6064 0 8.31378ZM1.71429 8.19906C1.71429 11.4821 4.37588 14.1433 7.65945 14.1433C10.943 14.1433 13.6046 11.4821 13.6046 8.19906C13.6046 4.916 10.943 2.25481 7.65945 2.25481C4.37591 2.25481 1.71429 4.916 1.71429 8.19906Z" fill="#C0C1C6"/>
                    </svg>
                    </span>
            </div>
            <div class="movie-app__col-xs-3 movie-app__col-sm-6 movie-app__pt-2" style="padding-left:0;">
                <p v-show="search" v-on:click="search = ''" class="movie-app__link" style="margin-top:5px;">Abbrechen</p>
            </div>
        </div>
        <div class="movie-app__row">
            <div class="movie-app__col-xs-9 movie-app__col-sm-6 movie-app__pt-2">
                <select v-model="selectedDay" @change="selectDay()" class="movie-app__form-control movie-app__mb-2">
                    <option value="" selected>Datum wählen</option>
                    <option v-for="product in products" :value="product.id" :key="product.id">{{ product.name }}</option>
                </select>
            </div>
            <div class="movie-app__col-xs-12 movie-app__col-sm-12 movie-app__col-md-4 movie-app__pt-2">
                <button @click="actDay()" class="movie-app__btn movie-app__mr-2" :class="{ active: aktDayButton}">Heute</button> 
                <button @click="allData()" class="movie-app__btn" :class="{ active: !selectedDay && !aktDayButton}">Aktuelle Woche</button>
            </div>
        </div>
        <div class="movie-app__row">
            <div v-if="movieFound" class="movie-app__col-xs-12 movie-app__pt-2">
                <p><b>Vorführungen {{ dayName }} </b></p>
            </div>
        </div>
    </div>
  <div class="movie-app__container">
    <div v-for="item in filteredList" :key="item.movie_id" class="movie-app__row movie-app__cinema-item">
      <div class="movie-app__col-xs-4 movie-app__col-sm-3 movie-app__col-md-2">
          <img :src="item.moviePoster" class="movie-app__movie-image" style=":black;"/>
      </div>
      <div class="movie-app__col-xs-8 movie-app__col-sm-9 movie-app__col-md-10">
          <h4 class="movie-app__list-name">{{ item.title }} {{ item.ov }}</h4>
          <p class="movie-app__list-address movie-app__mt-2">
              <span v-if="item.genre">{{ item.genre }} | </span><span v-if="item.fsk">ab {{ item.fsk }} | </span><span v-if="item.start">Start: {{ item.start }}</span>
          </p>
          <p>
            <router-link :to="{ name: 'movie', params: { id: item.movie_id, pageTitle: item.title }}" class="movie-app__link">
              Alle Infos zum Film
            </router-link>
          </p>
      </div>
      <div class="movie-app__col-xs-12 movie-app__pb-4">
        <Playtimes :playtimes="item.playtimes" :key="componentKey"/>
      </div>
    </div>
    <div class="movie-app__row"  v-if="!movieFound">
      <div class="movie-app__col-xs-12 movie-app__pt-5">
          <h2>Das Kino zeigt heute keine Vorstellungen.</h2>
      </div>
    </div>
  </div>

</template>

<script>
import Tab from '../components/Tab.vue'
import TabsWrapperDynamic from '../components/TabsWrapperDynamic.vue'
import Playtimes from '../components/Playtimes.vue'

export default {
  name: 'WrapAround',
  components: { Tab, TabsWrapperDynamic, Playtimes},
  props: ['id'],
  data() {
    return {
      componentKey: 0,
      selectedCinema: '',
      wrapperDynamicKey: 0,
      search: '',
      selectedDay: new Date().getDay(),
      aktDayButton: new Date().getDay(),
      aktDay: new Date().getDay(),
      cinema: [],
      movieList: [],
      countMovies: 0,
      dayName: '',
      movieFound: true,
      products: [
        {id: '4', name: 'Do. 11.01.', date: '11.01.'},
        {id: '5', name: 'Fr. 12.01.', date: '12.01.'},
        {id: '6', name: 'Sa. 13.01.', date: '13.01.'},
        {id: '0', name: 'So. 14.01.', date: '14.01.'},
        {id: '1', name: 'Mo. 15.01.', date: '15.01.'},
        {id: '2', name: 'Di. 16.01.', date: '16.01.'},
        {id: '3', name: 'Mi. 17.01.', date: '17.01.'},
      ],
    }
  },
  methods: {
    async getData() {
        await fetch("https://interface.datenlieferung-streamingdienste.de/api/entry/cinemaById/" + this.id)
            .then(res => res.json())
            .then(data => this.cinema = data)
            .catch(err => console.log(err.message));
    },
    async getDataMovie() {
        this.movieFound = true
        await fetch("https://interface.datenlieferung-streamingdienste.de/api/entry/movieByCinema/"+ this.id + this.selectedDay)
            .then(res => res.json())
            .then(data => this.movieList = data)
            .catch(err => console.log(err.message));
        this.countMovies = Object.keys(this.movieList).length
        this.wrapperDynamicKey += 1
        if ( this.selectedDay != '' ) {
            this.dayName = 'am ' + this.products.find(x => x.id == this.selectedDay).date
        } else {
            this.dayName = 'vom ' + this.products[0].date + ' - ' + this.products[this.products.length - 1].date
        }
        this.forceRerender()
    },
    allData() {
        this.aktDayButton = ''
        this.selectedDay = ''
        this.getDataMovie()
    },
    actDay() {
        this.aktDayButton = this.aktDay
        this.selectedDay = this.aktDay
        this.getDataMovie()
    },
    selectDay() {
        if ( this.selectedDay == this.aktDay ) {
            this.aktDayButton = this.aktDay
        } else {
            this.aktDayButton = ''
        }
        this.getDataMovie()
    },
    forceRerender() {
      console.log('----')
      console.log(this.componentKey)
      console.log('----')
      this.componentKey += 1;
    }
  },
  mounted() {
      this.getData(),
      this.getDataMovie()
  },
  computed: {
      filteredList() {
        const result = Object.values(this.movieList).filter(post => {
          return post.title.toLowerCase().includes(this.search.toLowerCase())
        })
        if (result.length) {
            this.movieFound = true
        } else {
            this.movieFound = false
        }
        return result
    }
  }
}
</script>